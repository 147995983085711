import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Subfooter from "../components/subfooter";
import SEO from "../components/seo";
import ApplyButton from "../components/applyButton";

function RolePage({ location, data }) {
  const content = data.rolePage.frontmatter;
  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title={content.title} />
      <div className="bloom-bg-white">
        <div className="bloom-max-w-4xl bloom-container bloom-mx-auto bloom-py-8 md:bloom-py-20">
          <div>
            <h1 className={`bloom-ht bloom-max-w-3xl`}>{content.title}</h1>
            <div className={`bloom-text-2xl bloom--mt-8 bloom-mb-8`}>
              Location: {content.location}
            </div>
            <div className={`bloom-text-2xl bloom--mt-8 bloom-mb-8`}>
              Position: {content.position}
            </div>
          </div>
          <div
            className="bloom-prose"
            dangerouslySetInnerHTML={{ __html: data.rolePage.html }}
          ></div>
          <ApplyButton link={content.link} className="bloom-mt-10" />
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    rolePage: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        location
        position
        link
      }
    }
  }
`;

export default RolePage;
